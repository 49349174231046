.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.container {
  padding: 5em 0em;
}

textarea {
  padding: 10px;
}

div.row {
  margin: 30px 0;
}

.hidden {
  display: none !important;
}

#items-preview,
#led-usage {
  border: none;
}

caption {
  margin-bottom: 15px;
}

button.report {
  position: fixed;
  top: 20px;
  right: 20px;
}
